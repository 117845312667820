<template>
  <b-card title="Session Info" class="m-2">
    <b-card>
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>

      <div v-if="!loading && session">
        <div class="mb-2">
          <small class="text-muted font-weight-bold text-uppercase"
            >Session Date</small
          >
        </div>

        <b-row
          ><b-col cols="1" class="lead" style="max-width: 32px">
            <b-icon
              icon="calendar"
              variant="primary"
              class="mr-1"
            ></b-icon></b-col
          ><b-col class="mb-2 lead text-primary"
            ><span style="display: inline-block">
              {{ session.dateFormatted }}</span
            >
            <span> at </span>
            <span style="display: inline-block">
              {{ session.timeFormatted }}
            </span></b-col
          ></b-row
        >

        <hr />

        <div class="mb-2">
          <small class="text-muted font-weight-bold text-uppercase">
            Tags
            <b-button
              @click="$refs.sessionTags.startEditing()"
              variant="link"
              class="m-0 p-0"
              size="sm"
              ><b-icon
                icon="pencil-square"
                aria-hidden="true"
              ></b-icon></b-button
          ></small>
        </div>

        <div>
          <TagEditor
            ref="sessionTags"
            :value="session.tags"
            @save="onSaveTags($event)"
            :bus="bus"
            :saving="savingTags"
          />
        </div>
      </div>
    </b-card>

    <b-card class="mt-3" header="Session Data">
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>

      <div v-if="!loading && session && relatedResponses.length > 0">
        <div
          v-for="(relatedResponse, index) in relatedResponses"
          :key="index"
          class="mb-2 d-flex justify-content-between border p-3 eu-default-font"
        >
          <div>
            <div>BEFORE</div>
            <div class="font-weight-bold my-1">{{relatedResponse.before.text}}</div>
            <div class="d-flex justify-content-between">
              <div>
                <div v-for="(option, index) in relatedResponse.before.options" :key="index">{{option}}</div>
              </div>
              <div v-if="relatedResponse.before.values">
                <div v-for="(value, index) in relatedResponse.before.values" :key="index">{{Number(value).toFixed(2)}}</div>
              </div>
            </div>
          </div>
          <div>
            <div>AFTER</div>
            <div class="font-weight-bold my-1">{{relatedResponse.after.text}}</div>
            <div class="d-flex justify-content-between">
              <div>
                <div v-for="(option, index) in relatedResponse.after.options" :key="index">{{option}}</div>
              </div>
              <div class="text-right">
                <div v-for="(value, index) in relatedResponse.after.values" :key="index">{{Number(value).toFixed(2)}}</div>
              </div>
            </div>
          </div>
          <div>
            <div>COMPARISON</div>
            <div class="font-weight-bold my-1">Change Over The Session</div>
            <div v-if="relatedResponse.changes">
              <div v-for="(change, index) in relatedResponse.changes" :key="index">
                <div v-if="change" :style="change > 0 ? {color: '#23c52e'} : {color: 'red'}">
                  {{change > 0 ? '+' : '-'}}
                  {{Math.abs(change)}}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <h5 class="text-center text-sm-left">
          Action Items
          <b-button
            @click="$refs.sessionNotes.startEditing()"
            variant="link"
            class="m-0 p-0"
            ><b-icon icon="pencil-square" aria-hidden="true"></b-icon
          ></b-button>
        </h5>

        <NoteEditor
          ref="sessionNotes"
          :notes="session.notes"
          @save="onSaveNotes($event)"
          :bus="bus"
          :saving="savingNotes"
          placeholder="Click the edit button to make a list of things you want to follow up on from your practice."
        />
      </div>
    </b-card>

    <b-card
      class="mt-3"
      header="My Responses"
      body-class="p-2"
      body-bg-variant="light"
    >
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>

      <b-form-row v-if="sessionResponses">
        <b-col
          lg="6"
          v-for="(response, index) in sessionResponses"
          :key="index"
          class="mb-2"
        >
          <b-card>
            <QuestionDisplay
              :index="index"
              :session="session"
              :question="response.question"
              :bus="bus"
            />
          </b-card>
        </b-col>
      </b-form-row>
    </b-card>
    <QuestionsAndAnswersSection :sessionId="sessionId" :bus="bus"/>
    <div class="d-flex justify-content-between">
      <b-button class="mt-3" @click="$router.go(-1)" variant="primary" size="sm">
        <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon>&nbsp;Back
      </b-button>
      <b-button class="mt-3 ml-2" @click="handleDeleteClick" variant="danger" size="sm">
        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>&nbsp;Delete
      </b-button>
    </div>

    <b-modal id="modal-share" title="Shareable Link" hide-footer>
      <p>Copy this link to share your audio response.</p>

      <b-input-group>
        <b-form-input id="share-url" v-model="shareUrl" readonly></b-form-input>
        <b-input-group-append>
          <b-button text="Copy" variant="primary" @click="copyShareUrl()"
            >Copy</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-modal>
  </b-card>
</template>

<script>
import Vue from "vue";
import API from "@/api";
import QuestionsAndAnswersSection from "@/components/sections/QuestionsAndAnswersSection.vue";
import QuestionDisplay from "@/components/QuestionDisplay.vue";
import NoteEditor from "@/components/NoteEditor.vue";
import TagEditor from "@/components/TagEditor.vue";

export default {
  name: "Session",
  components: {
    QuestionDisplay,
    TagEditor,
    NoteEditor,
    QuestionsAndAnswersSection,
  },
  created() {
    this.fetchData();

    this.bus.$on("share", (url) => {
      console.log(url);
      this.shareUrl = url;
      this.$bvModal.show("modal-share");
    });
  },
  data() {
    return {
      session: {
        questions: null,
      },
      sessionResponses: [],
      bus: new Vue(),
      loading: true,
      toggle: true,
      shareUrl: "",
      sessionId: null,
      savingNotes: false,
      savingTags: false,
      relatedResponses: []
    };
  },
  methods: {
    async handleDeleteClick() {
      let message = "Are you sure you want to delete session " + this.session._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          let filter = {
            _id: this.session._id,
          };
          await API.delete("sessions", filter);
          this.$router.go(-1);
        }
      });
    },
    async fetchData() {
      const BSON = require("bson");

      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("sessions");

      this.sessionId = this.$route.params.id;
      let filter = {
        _id: new BSON.ObjectID(this.sessionId),
      };
      const sessionData = await mongoCollection.findOne(filter);

      if (sessionData) {
        sessionData.dateFormatted = new Date(sessionData.startDateTime).toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );

        sessionData.timeFormatted = new Date(sessionData.startDateTime).toLocaleTimeString(
          "en-US"
        );
      }

      const responseData = await API.getResponsesBySessionId(this.sessionId, true);

      if (responseData.result) {
        responseData.result.forEach(response => {
          if (response.question && response.question.relatedQuestionId) {
            const relatedResponse = responseData.result.find(r => r.question._id.toString() === response.question.relatedQuestionId);

            let beforeResponse = {};
            let afterResponse = {};

            if (relatedResponse && response.question.order <= relatedResponse.question.order) {
              beforeResponse = response;
              afterResponse = relatedResponse;
              this.relatedResponses.push({
                before: {
                  text: beforeResponse.question.text,
                  options: beforeResponse.question.options,
                  values: beforeResponse.options
                },
                after: {
                  text: afterResponse.question.text,
                  options: afterResponse.question.options,
                  values: afterResponse.options
                },
                changes: beforeResponse.options.map((option, index) => Number((afterResponse.options[index] - option)/option* 100).toFixed(2))
              });
            }
          }
        });
      }

      this.session = sessionData;
      this.sessionResponses = responseData.result;
      this.loading = false;
    },
    copyShareUrl() {
      let urlText = document.getElementById("share-url");

      urlText.select();
      urlText.setSelectionRange(0, 99999);

      document.execCommand("copy");
    },
    async downloadMotionData() {
      let motionDataUrl = await API.createPresignedGetUrl(
        this.session._id,
        this.session.motion.filename
      );
      window.location.href = motionDataUrl;
    },
    async onSaveTags(source) {
      if (source.editedValue != source.value) {
        this.savingTags = true;

        let filter = {
          _id: this.session._id,
        };

        let update = {
          $set: {
            tags: source.editedValue,
          },
        };

        try {
          let result = await API.update("sessions", filter, update);
          console.log(result);
          this.session.tags = source.editedValue;
        } catch (e) {
          console.log(e);
        }
        this.savingTags = false;
      }
    },
    async onSaveNotes(source) {
      if (source.newNotes != source.notes) {
        this.savingNotes = true;

        let filter = {
          _id: this.session._id,
        };

        let update = {
          $set: {
            notes: source.newNotes,
          },
        };

        try {
          let result = await API.update("sessions", filter, update);
          console.log(result);
          this.session.notes = source.newNotes;
        } catch (e) {
          console.log(e);
        }

        this.savingNotes = false;
      }
    },
  },
};
</script>