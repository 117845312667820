<template>
  <div>
    <div v-if="saving" class="text-muted small text-center text-sm-left">
      <b-spinner variant="primary" small></b-spinner> Saving
    </div>

    <div
      class="text-muted small text-center text-sm-left"
      v-if="!editing && !saving"
    >
      {{ displayNotes }}
    </div>

    <div v-if="editing">
      <b-form-textarea
        size="sm"
        class="text-muted small"
        v-model="newNotes"
      ></b-form-textarea>

      <b-button
        size="sm"
        class="mt-2 mr-1"
        variant="primary"
        @click="stopEditing(true)"
        :disabled="notes == newNotes"
      >
        <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
        Save Changes</b-button
      >

      <b-button
        size="sm"
        class="mt-2"
        variant="danger"
        @click="stopEditing(false)"
      >
        <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
        Cancel</b-button
      >
    </div>
  </div>
</template>


<script>
//import API from "@/api";

export default {
  props: ["notes", "bus", "saving", "placeholder"],
  data() {
    return {
      editing: false,
      newNotes: "test",
    };
  },
  created() {
    this.bus.$on("editing", (source) => {
      if (source != this) {
        this.stopEditing(false);
      }
    });
  },
  computed: {
    displayNotes: function () {
      return (
        this.notes || this.placeholder || "Click the edit button to add notes"
      );
    },
  },
  methods: {
    startEditing() {
      this.newNotes = this.notes;
      this.editing = true;
      this.bus.$emit("editing", this);
    },
    async stopEditing(save = true) {
      this.editing = false;

      if (save) {
        this.$emit("save", this);
      }
    },
  },
};
</script>