<template>
  <div>
    <h5 class="text-primary mb-4 text-center text-sm-left">
      Question {{ this.index + 1 }}
    </h5>

    <b-row
      ><b-col sm="4" class="text-center text-sm-left" style="min-height: 3em">
        {{ question.text }}
        <hr class="d-block d-sm-none" /> </b-col
      ><b-col sm="8" class="text-center text-sm-right">
        <span v-if="!playing">
          <b-button
            size="sm"
            @click="toggleAudio()"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-icon icon="play" aria-hidden="true"></b-icon>
          </b-button>
        </span>
        <span v-if="playing">
          <b-button
            size="sm"
            @click="toggleAudio()"
            variant="secondary"
            class="btn-circle mr-1"
          >
            <b-icon icon="pause" aria-hidden="true"></b-icon>
          </b-button>
        </span>

        <span>
          <b-button
            size="sm"
            @click="exportText()"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-spinner variant="primary" small v-if="exporting"></b-spinner>
            <b-icon icon="file-text" aria-hidden="true" v-else></b-icon>
          </b-button>
        </span>
        <b-button
          size="sm"
          @click="rewindAudio()"
          variant="primary"
          class="btn-circle mr-1"
        >
          <b-icon icon="skip-start" aria-hidden="true"></b-icon>
        </b-button>

        <b-button
          size="sm"
          @click="downloadAudio()"
          variant="primary"
          class="btn-circle mr-1"
        >
          <b-icon icon="download" aria-hidden="true"></b-icon>
        </b-button>
        <!-- 
        <b-button size="sm" variant="primary" class="btn-circle mr-1">
          <b-icon icon="tag" aria-hidden="true"></b-icon>
        </b-button> -->

        <b-button
          size="sm"
          @click="shareQuestion()"
          variant="primary"
          class="btn-circle mr-1"
        >
          <b-icon icon="share" aria-hidden="true"></b-icon>
        </b-button>
        
        <ShareNetwork
          network="facebook"
          :url="socialLink"
          :title="question.text"
          description=""
          quote=""
        >
          <b-button
            size="sm"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-icon icon="facebook" aria-hidden="true"></b-icon>
          </b-button>
        </ShareNetwork>
        
        <ShareNetwork
          network="twitter"
          :url="socialLink"
          :title="question.text"
          description=""
          quote=""
        >
          <b-button
            size="sm"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-icon icon="twitter" aria-hidden="true"></b-icon>
          </b-button>
        </ShareNetwork>

        <!-- <b-dropdown
          size="sm"
          variant="primary"
          toggle-class="btn-circle mr-1"
          no-caret
          right
        >
          <template #button-content>
            <b-icon icon="three-dots" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item @click="shareQuestion()"
            >Share Response</b-dropdown-item
          >
          <b-dropdown-item href="#">Edit Tags</b-dropdown-item>
        </b-dropdown> -->
      </b-col></b-row
    >

    <div
      class="progress-wrapper mb-4 mt-4"
      @click="setProgress($event, $refs.audioProgress)"
      ref="audioProgress"
    >
      <b-progress
        :value="progress"
        :max="1"
        class="notransition"
        height=".75rem"
      ></b-progress>
    </div>

    <!-- <pre>
      audioCurrentTime: {{ audioCurrentTime }}
    audioDuration: {{ audioDuration }}
    question.duration: {{ question.duration }}
    dbeug: {{ debug }}
    </pre> -->

    <hr />

    <h6 class="text-center text-sm-left">
      Notes
      <b-button
        @click="$refs.questionNotes.startEditing()"
        variant="link"
        class="m-0 p-0"
        ><b-icon icon="pencil-square" aria-hidden="true"></b-icon
      ></b-button>
    </h6>

    <NoteEditor
      ref="questionNotes"
      :notes="question.notes"
      @save="onSaveNotes($event)"
      :bus="bus"
      :saving="savingNotes"
    />

    <!-- <div class="text-center text-sm-left">
      <b-badge class="mr-1" variant="primary">Example Tag</b-badge>
      <b-badge class="mr-1" variant="primary">Example Tag</b-badge>
      <b-badge class="mr-1" variant="primary">Example Tag</b-badge>
    </div> -->
  </div>
</template>

<script>
import API from "@/api";
import NoteEditor from "@/components/NoteEditor.vue";

export default {
  components: {
    NoteEditor,
  },
  props: ["index", "session", "question", "bus"],
  created() {
    this.audio = new Audio();

    this.audio.addEventListener("play", () => {
      this.playing = true;
    });

    this.audio.addEventListener("loadedmetadata", () => {
      this.audioDuration = this.audio.duration;
    });

    this.audio.addEventListener("ended", () => {
      this.playing = false;
    });

    this.audio.addEventListener("timeupdate", () => {
      if (this.playing) {
        this.audioCurrentTime = this.audio.currentTime;
      }
    });

    this.bus.$on("playing", (source) => {
      if (source != this) {
        this.pauseAudio();
      }
    });
    
    API.createShareToken(this.session._id, this.question._id).then(token => this.token = token);
  },
  data() {
    return {
      playing: false,
      audio: null,
      audioCurrentTime: 0,
      audioDuration: 0,
      lastRefresh: 0,
      refreshTimeout: 10 * 60 * 1000,
      savingNotes: false,
      debug: "",
      token: "",
      exporting: false
    };
  },
  computed: {
    progress() {
      return this.audioCurrentTime / this.audioDuration;
    },
    socialLink() {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/shared/" +
        this.token;
      return url;
    }
  },
  methods: {
    refreshUrl() {
      if (Date.now() - this.lastRefresh > this.refreshTimeout) {
        console.log("Refreshing URL.");
        this.url = this.question.audio_url;
        this.audio.src = this.url;
        this.audioDuration = this.question.duration;
        this.lastRefresh = Date.now();
      }
    },
    async exportText() {
      try {
        this.exporting = true;
        const text = await API.speechToText(this.question.audio_url);
        this.exporting = false;
        const a = document.createElement("a");
        const fileName = "audio_text.txt";
        const blob = new Blob([text], {type: "text/plain;charset=utf-8"});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch(e) {
        alert('Audio to text error:' + e);
      }
    },
    toggleAudio() {
      if (this.audio.paused) {
        this.playAudio();
      } else {
        this.pauseAudio();
      }
    },
    async downloadAudio() {
      await this.refreshUrl();
      window.location.href = this.url;
    },
    async playAudio() {
      await this.refreshUrl();

      this.audio.play();
      this.bus.$emit("playing", this);
    },
    pauseAudio() {
      this.playing = false;
      this.audio.pause();
    },
    rewindAudio() {
      this.audioCurrentTime = 0;
      this.audio.currentTime = this.audioCurrentTime;
    },
    async setProgress(event, element) {
      await this.refreshUrl();

      let rect = element.getBoundingClientRect();
      let x = (event.clientX - rect.left) / rect.width;

      this.audioCurrentTime = x * this.audioDuration;
      this.audio.currentTime = this.audioCurrentTime;
    },
    async shareQuestion() {
      let url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/shared/" +
        this.token;
      console.log(this.token);
      console.log(url);

      this.bus.$emit("share", url);
    },

    async onSaveNotes(source) {
      if (source.newNotes != source.notes) {
        this.savingNotes = true;

        let filter = {
          _id: this.session._id,
        };

        let field = "questions." + this.index + ".notes";

        let update = {
          $set: {
            [field]: source.newNotes,
          },
        };

        try {
          let result = await API.update("sessions", filter, update);
          console.log(result);
          this.question.notes = source.newNotes;
        } catch (e) {
          console.log(e);
        }

        this.savingNotes = false;
      }
    },
  },
};
</script>