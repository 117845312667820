<template>
  <b-card
    class="mt-3"
    header="Questions And Responses"
    body-class="p-2"
    body-bg-variant="light"
  >
    <div v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <div v-if="!responses.length && !loading" class="p-2">No questions and answers</div>

    <b-form-row v-else>
      <b-col
        lg="12"
        v-for="(response, index) in responses"
        :key="index"
        class="mb-2"
      >
        <ResponseCard :id="`response-card-${index}`" :response="response" :bus="bus" :index="index"/>
      </b-col>
    </b-form-row>
  </b-card>
</template>

<script>
import API from "@/api";

import ResponseCard from "@/components/ResponseCard.vue";
export default {
  props: ["sessionId", "bus"],
  components: {
    ResponseCard,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      responses: []
    };
  },
  mounted() {
  },
  methods: {
    async fetchData() {
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const responseCollection = mongo.db("eunoe").collection("responses");
      const questionCollection = mongo.db("eunoe").collection("questions");
      const responses = await responseCollection.find({"sessionId": this.sessionId.toString()});
      const questions = await questionCollection.find();
      const newResponses = [];
      responses.forEach(res => {
        res.question = questions.find( question => {
          return question._id.toString() === res.questionId;
        });

        if (res.question && res.question.dashboard) {
          res.question._id = res.question._id.toString();
          newResponses.push(res);
        }
      });
      newResponses.sort((a, b) => { return a.question.order - b.question.order})
      this.responses = newResponses;

      // const sessionId = this.$route.params.id;
      // let filter = {
      //   _id: new BSON.ObjectID(sessionId),
      // };
      // const result = await mongoCollection.findOne(filter);

      // result.dateFormatted = new Date(result.startDateTime).toLocaleDateString(
      //   "en-US",
      //   {
      //     year: "numeric",
      //     month: "long",
      //     day: "numeric",
      //   }
      // );

      // result.timeFormatted = new Date(result.startDateTime).toLocaleTimeString(
      //   "en-US"
      // );

      // this.session = result;
      this.loading = false;
    },
  },
};
</script>
