<template>
  <div>
    <div v-if="!saving && !editing" class="text-left">
      <b-badge
        v-for="(tag, index) in value"
        :key="index"
        variant="primary"
        class="font-weight-normal p-1 mr-1"
        >{{ tag }}</b-badge
      >

      <div
        v-if="!value || value == '' || value.length == 0"
        class="small text-muted"
      >
        Click the edit button to add tags.
      </div>
    </div>

    <div v-if="editing">
      <b-form-tags
        v-model="editedValue"
        no-outer-focus
        class="border-0 p-0"
        @input="tagsChanged()"
      >
        <template
          v-slot="{
            inputAttrs,
            inputHandlers,
            addTag,
            removeTag,
          }"
        >
          <div class="mb-2">
            <b-form-tag
              v-for="tag in editedValue"
              @remove="removeTag(tag)"
              :key="tag"
              :title="tag"
              variant="primary"
              class="mr-1"
              :disabled="saving"
              >{{ tag }}</b-form-tag
            >
          </div>

          <b-input-group class="mb-2">
            <b-form-input
              v-bind="inputAttrs"
              v-on="inputHandlers"
              placeholder='Enter a tag and click "Add".'
              class="form-control"
              size="sm"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                @click="addTag()"
                variant="primary"
                size="sm"
                :disabled="saving"
                ><b-icon icon="plus-circle-fill" aria-hidden="true" />
                Add</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </template>
      </b-form-tags>

      <div>
        <b-button
          size="sm"
          class="mr-1"
          variant="primary"
          @click="stopEditing()"
          :disabled="saving"
        >
          <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
          Done</b-button
        >

        <span v-if="saving" class="text-muted">
          <b-spinner variant="primary" small></b-spinner> Saving
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "bus", "saving"],
  data() {
    return {
      editedValue: [],
      editing: false,
    };
  },
  created() {
    this.bus.$on("editing", (source) => {
      if (source != this) {
        this.stopEditing(false);
      }
    });
  },
  methods: {
    async tagsChanged() {
      this.$emit("save", this);
    },
    startEditing() {
      this.editedValue = this.value;
      this.editing = true;
      this.bus.$emit("editing", this);
    },
    async stopEditing() {
      this.editing = false;
    },
  },
};
</script>