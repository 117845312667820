<template>
  <b-card>
    <div>
      <h5 class="text-primary mb-4 text-center text-sm-left">
        Question {{ this.index + 1 }}
      </h5>

      <b-row
        ><b-col sm="7" class="text-center text-sm-left" style="min-height: 3em">
          {{ response.question.text }}
          <hr class="d-block d-sm-none" /> </b-col
        ><b-col sm="5" class="text-center text-sm-right">
          <span v-if="!playing">
            <b-button
              size="sm"
              @click="toggleAudio()"
              variant="primary"
              class="btn-circle mr-1"
            >
              <b-icon icon="play" aria-hidden="true"></b-icon>
            </b-button>
          </span>
          <span v-if="playing">
            <b-button
              size="sm"
              @click="toggleAudio()"
              variant="secondary"
              class="btn-circle mr-1"
            >
              <b-icon icon="pause" aria-hidden="true"></b-icon>
            </b-button>
          </span>

          <b-button
            size="sm"
            @click="rewindAudio()"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-icon icon="skip-start" aria-hidden="true"></b-icon>
          </b-button>

          <b-button
            size="sm"
            @click="downloadAudio()"
            variant="primary"
            class="btn-circle mr-1"
          >
            <b-icon icon="download" aria-hidden="true"></b-icon>
          </b-button>
        </b-col></b-row
      >

      <div
        class="progress-wrapper mb-4 mt-4"
        @click="setProgress($event, $refs.audioProgress)"
        ref="audioProgress"
      >
        <b-progress
          :value="progress"
          :max="1"
          class="notransition"
          height=".75rem"
        ></b-progress>
      </div>
      <div v-if="response.question.type === QUESTION_TYPES.SLIDER_QUESTION">
        <ThoughtsDisplay
          :startThoughts="0"
          :endThoughts="answers[0].optionAnswer"
          :containerId="`thoughts-display-${id}`"
          :toggle="true"
        />
      </div>
      <div v-else-if="response.question.type === QUESTION_TYPES.SIX_SLIDER_QUESTION">
        <MoodDisplay
          :label="`start_${response._id.toString()}`"
          :startMood="answers"
          :endMood="answers"
          :toggle="true"
          :customFields="Object.keys(answers)"
          :customLabels="Object.keys(answers)"
        />
      </div>
      <b-table :items="answers" small class="small" fixed v-else>
        <template #cell(questionOption)="data">
          <span class="text-primary">{{ data.value }}</span>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>

import API from "@/api";
import constants from "@/constants";
import MoodDisplay from "@/components/MoodDisplay.vue";
import ThoughtsDisplay from "@/components/ThoughtsDisplay.vue";
export default {
  props: ["id", "response", "bus", "index"],
  created() {
    this.audio = new Audio();

    this.audio.addEventListener("play", () => {
      this.playing = true;
    });

    this.audio.addEventListener("loadedmetadata", () => {
      this.audioDuration = this.audio.duration;
    });

    this.audio.addEventListener("ended", () => {
      this.playing = false;
    });

    this.audio.addEventListener("timeupdate", () => {
      if (this.playing) {
        this.audioCurrentTime = this.audio.currentTime;
      }
    });

    this.bus.$on("playing", (source) => {
      if (source != this) {
        this.pauseAudio();
      }
    });

    if (this.response.question.type === this.QUESTION_TYPES.SIX_SLIDER_QUESTION) {
      const sixSliderAnswers = {};
      (this.response.question.options || []).forEach((questionOption, index) => {
        sixSliderAnswers[questionOption.toLowerCase()] = (this.response.options || [])[index];
      });
      this.answers = sixSliderAnswers;
    } else {
      this.answers = (this.response.question.options || []).map((questionOption, index) =>
        ({questionOption, optionAnswer: (this.response.options || [])[index]}));
    }
  },
  data() {
    return {
      playing: false,
      audio: null,
      audioCurrentTime: 0,
      audioDuration: 0,
      lastRefresh: 0,
      refreshTimeout: 10 * 60 * 1000,
      answers: [],
      QUESTION_TYPES: constants.QUESTION_TYPES
    };
  },
  components: {
    MoodDisplay,
    ThoughtsDisplay
  },
  computed: {
    progress() {
      return this.audioCurrentTime / this.audioDuration;
    },
  },
  methods: {
    async refreshUrl() {
      if (Date.now() - this.lastRefresh > this.refreshTimeout) {
        console.log("Refreshing URL.");
        this.url = await API.createPresignedGetUrl(
          this.response.sessionId,
          this.response.audio_name
        );
        this.audio.src = this.url;
        this.audioDuration = this.response.audio_duration;
        this.lastRefresh = Date.now();
      }
    },
    toggleAudio() {
      if (this.audio.paused) {
        this.playAudio();
      } else {
        this.pauseAudio();
      }
    },
    async downloadAudio() {
      await this.refreshUrl();
      window.location.href = this.url;
    },
    async playAudio() {
      await this.refreshUrl();

      this.audio.play();
      this.bus.$emit("playing", this);
    },
    pauseAudio() {
      this.playing = false;
      this.audio.pause();
    },
    rewindAudio() {
      this.audioCurrentTime = 0;
      this.audio.currentTime = this.audioCurrentTime;
    },
    async setProgress(event, element) {
      await this.refreshUrl();

      let rect = element.getBoundingClientRect();
      let x = (event.clientX - rect.left) / rect.width;

      this.audioCurrentTime = x * this.audioDuration;
      this.audio.currentTime = this.audioCurrentTime;
    },
  },
};
</script>