<template>
  <div>
    <div :id="this.label + '-display'" class="mood-display mx-auto text-center">
      <b-img src="/images/moodDisplay.svg" fluid></b-img>
    </div>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";

export default {
  props: ["label", "startMood", "endMood", "toggle", "customLabels", "customFields"],
  data() {
    return {
      labels: this.customLabels ? this.customLabels : ["HAPPY", "HOPEFUL", "DISGUSTED", "AFRAID", "ANGRY", "SAD"],
      fields: this.customFields ? this.customFields : ["happy", "hopeful", "annoyed", "scared", "angry", "sad"],
      ellipses: [],
      polygon: null,
    };
  },
  watch: {
    toggle: function (value) {
      if (!value) {
        this.update(this.startMood);
      } else {
        this.update(this.endMood);
      }
    },
    startMood: {
      handler() {
        if (!this.toggle) this.update(this.startMood);
      },
    },
    endMood: {
      handler() {
        if (this.toggle) this.update(this.endMood);
      },
    },
  },
  methods: {
    polarToCartesian(radius, angle) {
      let x = radius * Math.cos(angle) + 50;
      let y = radius * Math.sin(angle) + 50;
      return [x, y];
    },
    getPoints(mood) {
      let maxRadius = 37.5;
      let minRadius = 7.5;

      let fieldCount = this.fields.length;

      let angle = (Math.PI * 2) / fieldCount;
      let baseAngle = Math.PI / -2;
      let points = [];

      for (let i = 0; i < fieldCount; i++) {
        let field = this.fields[i];
        let value = mood[field];

        let radius = value * (maxRadius - minRadius) + minRadius;
        let point = this.polarToCartesian(radius, baseAngle + angle * i);

        points.push(point);
      }
      return points;
    },

    update(mood) {
      let points = mood ? this.getPoints(mood) : [];

      this.polygon.animate(1000).plot(points);
      for (let i = 0; i < points.length; i++) {
        let point = points[i];
        this.ellipses[i].animate(1000).center(point[0], point[1]);
      }
    },
  },
  mounted() {
    console.log("Mounting Mood");

    let container = "#" + this.label + "-display";
    let draw = SVG().addTo(container).size("100%", "100%");
    draw.viewbox(0, 0, 100, 100);

    // Create initial points and lines.

    const angle = Math.PI / 3;
    const baseAngle = Math.PI / -2;
    const radius = 7.5;
    const initialPoints = [
      this.polarToCartesian(radius, angle * 0 + baseAngle),
      this.polarToCartesian(radius, angle * 1 + baseAngle),
      this.polarToCartesian(radius, angle * 2 + baseAngle),
      this.polarToCartesian(radius, angle * 3 + baseAngle),
      this.polarToCartesian(radius, angle * 4 + baseAngle),
      this.polarToCartesian(radius, angle * 5 + baseAngle),
    ];

    this.polygon = draw.polygon();
    this.polygon.plot(initialPoints);
    this.polygon.stroke({ width: 0.5, color: "#60B7B1" });
    this.polygon.fill({ color: "#60B7B1", opacity: 0.125 });

    for (let i = 0; i < 6; i++) {
      let initialPoint = initialPoints[i];
      this.ellipses[i] = draw
        .ellipse(3)
        .fill({ color: "#60B7B1" })
        .stroke({ color: "#60B7B1", width: 0.5 })
        .center(initialPoint[0], initialPoint[1]);
    }

    // Text labels

    let fontInfo = {
      size: 2.5,
      family: "Montserrat",
      weight: "bold",
      anchor: "middle",
    };
    let strokeInfo = { color: "#eee", width: 0.35 };

    let centers = [
      [50, 7],
      [88, 24],
      [88, 76],
      [50, 93],
      [12, 76],
      [12, 24],
    ];

    for (let i = 0; i < 6; i++) {
      let center = centers[i];
      let label = this.labels[i];

      let group = draw.group();

      group.rect(20, 5.5).radius(1).fill("#fff").stroke(strokeInfo);
      group.text(label).font(fontInfo).amove(10, 0.5).fill("#60B7B1");

      group.center(center[0], center[1]);
    }

    // Process points if ready.

    // if (this.startMood != null) {
    //   this.update(this.startMood);
    // }

    if (this.endMood != null) {
      this.update(this.endMood);
    }

    console.log("Mood Mounted");
  },
};
</script>