<template>
  <b-card title="Admin Dashboard" class="m-2">
    <b-card no-body header="Sessions">
      <b-table
        hover
        small
        outlined
        stacked="sm"
        :items="sessions"
        :busy="loading"
        :fields="fields"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template v-slot:cell(actions)="row">
          <b-button @click="deleteSessionConfirm(row.item)">
            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          </b-button>
          <b-button class="ml-1" @click="setUser(row.item)">
            <b-icon icon="person-fill" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center my-5">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="sessionsTotal"
        :per-page="perPage"
        align="center"
      ></b-pagination>
    </b-card>
    <b-modal
      id="set-email-modal"
      ref="modal"
      :title="sessionInSettingEmail ? `Set email of ${sessionInSettingEmail._id.toString()}` : 'Set email'"
      @show="handleSetEmailModalShow"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Email"
          label-for="email-input"
          invalid-feedback="Email is required"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import API from "@/api";
//import moment from "moment";

export default {
  name: "AdminSessions",
  data() {
    return {
      loading: true,
      filter: "",
      fields: [
        { key: "userId", sortable: true },
        { key: "userCode", sortable: true },
        { key: "responseCount", sortable: true },
        { key: "startDateTime", sortable: true },
        { key: "actions", sortable: false }
      ],
      email: '',
      emailState: null,
      sessionInSettingEmail: null,
      currentPage: 1,
      perPage: 15,
      sessions: [],
    };
  },
  computed: {
    sessionsTotal() {
      return this.sessions.length;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.emailState = valid
      return valid
    },
    handleSetEmailModalShow() {
      this.email = this.sessionInSettingEmail.email;
    },
    resetModal() {
      this.email = ''
      this.emailState = null
      this.sessionInSettingEmail = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const userCollection = mongo.db("eunoe").collection("users");
      const usersData = await userCollection.find();
      const newUser = usersData.find(u => u.email === this.email);
      let result = null;

      if (newUser && newUser._id.toString() !== this.sessionInSettingEmail.userId) {
        await API.update(
          "sessions",
          {
            _id: this.sessionInSettingEmail._id
          },
          { $set: {userId: newUser._id.toString()} }
        );
      } else if (!newUser) {
        result = await API.insert(
          "users",
          {
            email: this.email,
            shareData: true
          }
        )
        await API.update(
          "sessions",
          {
            _id: this.sessionInSettingEmail._id
          },
          {
            $set: {userId: result.insertedId._id.toString()}
          }
        )
      }
      this.fetchData();
      this.$nextTick(() => {
        this.$bvModal.hide('set-email-modal')
      })
    },
    setUser(session) {
      this.sessionInSettingEmail = session;
      this.$bvModal.show('set-email-modal');
    },
    deleteSessionConfirm(session) {
      let message =
        "Are you sure you want to delete session " + session._id + "?";

      this.$bvModal.msgBoxConfirm(message).then(async (value) => {
        if (value) {
          this.deleteSession(session);
        } else {
          // Delete cancelled.
        }
      });
    },
    async deleteSession(session) {
      let filter = {
        _id: session._id,
      };
      let result = await API.delete("sessions", filter);
      console.log(result);

      this.sessions = [];
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      const realmApp = API.realmApp;
      const mongo = realmApp.services.mongodb("mongodb-atlas");
      const mongoCollection = mongo.db("eunoe").collection("sessions");
      const responses = mongo.db("eunoe").collection("responses");
      const questions = mongo.db("eunoe").collection("questions");
      const users = mongo.db("eunoe").collection("users");
      const questionsData = await questions.find();
      const responsesData = await responses.find();
      const usersData = await users.find();

      let sessionResults = await mongoCollection.find({}, {sort: { startDateTime: -1 }});
      sessionResults.forEach((result) => {
        let sessionResponsesData = responsesData.filter(item => item.sessionId === result._id.toString());
        sessionResponsesData = sessionResponsesData.map(item => {
          const questionItemIndex = questionsData.findIndex(questionData => {
            return questionData._id.toString() == item.questionId;
          });
          return {...item, question: questionsData[questionItemIndex]};
        });
        sessionResponsesData = sessionResponsesData.filter(item => item.question);
        result.responseCount = sessionResponsesData.length;

        let options = {
          dateStyle: "long",
          timeStyle: "medium",
        };
        result.startDateTime = new Date(result.startDateTime).toLocaleString(
          "en-US",
          options
        );
        const sessionUser = usersData.find(u => u._id.toString() === result.userId);
        result.email = sessionUser ? sessionUser.email : null;
      });

      this.sessions = sessionResults;
      this.loading = false;
    },
  },
};
</script>