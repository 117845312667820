<template>
  <div class="mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <b-badge variant="primary" class="font-weight-normal p-1">Past</b-badge>
      <b-badge variant="primary" class="font-weight-normal p-1"
        >Present</b-badge
      >
      <b-badge variant="primary" class="font-weight-normal p-1">Future</b-badge>
    </div>
    <div :id="containerId || 'thoughts-container'" class="thoughts-display mt-2"></div>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";

export default {
  props: ["containerId", "label", "startThoughts", "endThoughts", "toggle"],
  data() {
    return {
      height: 16,
      ellipse: null,
      rect: null,
    };
  },
  methods: {},
  watch: {
    toggle: function (value) {
      if (!value) {
        let startPercent = this.startThoughts * 100 + "%";
        this.ellipse.animate(1000).center(startPercent, this.height / 2);
      } else {
        let endPercent = this.endThoughts * 100 + "%";
        this.ellipse.animate(1000).center(endPercent, this.height / 2);
      }
    },
    startThoughts: function (value) {
      let startPercent = this.startThoughts * 100 + "%";
      let endPercent = value * 100 + "%";
      let deltaPercent =
        Math.abs(this.endThoughts - this.startThoughts) * 100 + "%";

      if (!this.toggle) {
        this.ellipse.center(startPercent, this.height / 2);
      }

      if (this.startThoughts < this.endThoughts) {
        this.rect.move(startPercent, this.height / 2 - 8 / 2);
      } else {
        this.rect.move(endPercent, this.height / 2 - 8 / 2);
      }
      this.rect.size(deltaPercent, 8);
    },
    endThoughts: function (value) {
      let startPercent = this.startThoughts * 100 + "%";
      let endPercent = value * 100 + "%";
      let deltaPercent =
        Math.abs(this.endThoughts - this.startThoughts) * 100 + "%";

      if (this.toggle) {
        this.ellipse.center(endPercent, this.height / 2);
      }

      if (this.startThoughts < this.endThoughts) {
        this.rect.move(startPercent, this.height / 2 - 8 / 2);
      } else {
        this.rect.move(endPercent, this.height / 2 - 8 / 2);
      }
      this.rect.size(deltaPercent, 8);
    },
  },
  mounted() {
    console.log("Mounting Thoughts");

    let draw = SVG().addTo(`#${this.containerId || 'thoughts-container'}`).size("100%", this.height);

    let endPercent = "75";

    let deltaStartPercent = "25%";
    let deltaPercent = "50%";

    if (this.startThoughts != null && this.endThoughts != null) {
      endPercent = this.endThoughts * 100 + "%";

      deltaStartPercent =
        this.endThoughts < this.startThoughts
          ? this.endThoughts
          : this.startThoughts;
      deltaStartPercent = deltaStartPercent * 100 + "%";
      deltaPercent =
        Math.abs(this.endThoughts - this.startThoughts) * 100 + "%";

      console.log(deltaStartPercent + " " + deltaPercent);
    }

    draw
      .rect()
      .size("100%", 8)
      .center("50%", this.height / 2)
      .radius(4)
      .fill("#efefef");

    this.rect = draw
      .rect(deltaPercent, 8)
      .move(deltaStartPercent, this.height / 2 - 8 / 2)
      .radius(4)
      .fill("#60B7B1")
      .opacity(0.5);

    this.ellipse = draw
      .ellipse(16)
      .center(endPercent, this.height / 2)
      .fill("#60B7B1");

    console.log("Thoughts Mounted");
  },
};
</script>